



















    import { Vue, Component } from 'vue-property-decorator';
    import UiButton from '@/styleguide/elements/UiButton.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import deviceDetection from '@/common/device-detection';
    import bannerImg from '@img/frontend/app-banner__app-icon.png';
    import Thumbnail from '@/components/shared/Thumbnail.vue';
    import { pageMetadataService } from '@/common/page-metadata-service';
    import SvgImage from './SvgImage.vue';
    import DeepLinker from '../../common/deep-linker';
    import { ExternalLinkClickedEvent } from '@/common/custom-events/external-link-clicked-event';
    import { AppStoreUrls } from '@/constants';
    import storage from '@/common/storage';

    @Component({
        components: {
            UiButton,
            Thumbnail,
            SvgImage,
            UiHeading
        }
    })
    export default class AppBanner extends Vue {
        private displayBanner: boolean = storage.closedAppBannerDate === null;
        private deepLinker?: DeepLinker;

        private androidUrl(episodeId?: string): string {
            const url = episodeId ? `play/episode/${episodeId}` : 'play';
            const fallbackUrl = encodeURIComponent(`sesrplay://${url}`);

            return `intent://${url}#Intent;package=se.sr.android;scheme=sesrplay;S.browser_fallback_url=${fallbackUrl};end`;
        }

        private iosUrl(episodeId?: string): string {
            if (episodeId) {
                return `sesrplay://play?json=%7B%22type%22:%22showEpisode%22,%22id%22:${episodeId}%7D`;
            }

            return 'sesrplay://play';
        }

        private bannerLogo(): string {
            return bannerImg;
        }

        private buttonClicked(): void {
            if (!this.displayBanner) {
                return;
            }

            const episodeId =
                pageMetadataService?.pageView?.pageType === 'avsnitt' ? pageMetadataService?.pageView?.pageId : undefined;

            const deepUrl = deviceDetection.isIos ? this.iosUrl(episodeId) : this.androidUrl(episodeId);

            window.dispatchEvent(new ExternalLinkClickedEvent('', 'Ladda ner', deepUrl));

            this.deepLinker?.openURL(deepUrl);
        }

        private close(): void {
            storage.closedAppBannerDate = new Date();
            this.displayBanner = false;
        }

        private mounted(): void {
            this.displayBanner = deviceDetection.isSmartphoneOrTablet && !deviceDetection.isSafari && storage.closedAppBannerDate === null;
            this.deepLinker = new DeepLinker({
                onIgnored: (): void => {
                    window.location.replace(deviceDetection.isIos ? AppStoreUrls.Ios : AppStoreUrls.Android);
                }
            });
        }

        private destroyed(): void {
            this.deepLinker?.destroy();
            this.deepLinker = undefined;
        }
    }
